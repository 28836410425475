@charset "utf-8";
/* @import url(font.css); */
/* Noto Sans KR (korean) http://www.google.com/fonts/earlyaccess */
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 100; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 300; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 400; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 500; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 700; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 900; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format("opentype"); }


/* Reset */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,form,fieldset,p,button{margin:0;padding:0}
body,h1,h2,h3,h4,input,button,textarea,pre{font-family: 'Noto Sans KR', sans-serif; font-size:15px;color:#000}
body{text-align:left; word-break:break-word;}
img,fieldset,iframe{border:0 none}
li{list-style:none}
input,select,button{vertical-align:middle}
img{vertical-align:top}
i,em,address{font-style:normal}
label,button{cursor:pointer}
a{color:#000;text-decoration:none}
a:hover{cursor: pointer;}
hr{display:none}
legend{*width:0}
table{border-collapse:collapse;border-spacing:0}
caption{font-size:0; line-height:0; height:0}

/* Mobile Form Default */
input[type='text'],input[type='password'],input[type='image'],input[type='search']{-webkit-appearance:none;-webkit-border-radius:0}

/* Skip */
#skipnavigation{z-index:9999;position:absolute;left:0;top:0;width:100%;text-align:center}
#skipnavigation *{list-style:none;margin:0;padding:0;}
#skipnavigation a{display:block;width:100%;height:0;overflow:hidden;white-space:nowrap;}
#skipnavigation a:focus,
#skipnavigation a:active{display:block;width:100%;height:36px;color:#fff;background:#000;line-height:36px;text-decoration:underline !important;font-size:14px;}

::-webkit-input-placeholder {  color: #999999; font-weight: normal; font-size: 14px;  font-weight: 400; }
:-moz-placeholder { color: #999999; opacity: 1; font-weight: normal; font-size: 14px;  font-weight: 400;  }
::-moz-placeholder {  color: #999999; opacity: 1; font-weight: normal; font-size: 14px;  font-weight: 400; }
:-ms-input-placeholder {  color: #999999; font-weight: normal;font-size: 14px; font-weight: 400 }

body{ -ms-overflow-style: none; } body::-webkit-scrollbar { display: none; }
::-webkit-scrollbar{width: 10px}
::-webkit-scrollbar-track{background-color: #ffffff;}
::-webkit-scrollbar-thumb{background-color: #f5f5f5; border-radius: 10px}
::-webkit-scrollbar-thumb:hover{background: #222222}
::-webkit-scrollbar-button:start:decrement,::-webkit-scrollbar-button:end:increment{width:6px; height:6px; background: #ffffff}


* {box-sizing:border-box;}

.clear:after{content:''; display:block; clear:both;}


/* 
.baseSel {position: relative; background:#fff;}
.baseSel:after {content:''; position: absolute; right:20px; top:50%; width:14px; margin-top:-4px; height:8px;background:url('/images/bull_sel.png') center center no-repeat; }
.baseSel label {position: absolute; left:20px; top:22px; line-height: 16px; font-size: 16px; color:#333333; }
.baseSel select {opacity:0; width: 100%; height: 60px; position: relative; z-index:10; font-size: 16px;} */



.btnRight {text-align: right;}

.btnCenter {text-align: center; margin-bottom: 30px;}
.btnCenter a {margin-left:7px; }
.btnCenter a:first-child { margin-left:0; }

.btnRight2 {text-align: right; margin-top: 15px; margin-bottom: 15px;}
.btnRight2 a {margin-left:7px; }
.btnRight2 a:first-child { margin-left:0; }

.btnRight2 .button {padding:0px 14px; margin-left: 7px;}
.btnRight2 .saveIcon {font-size: 20px; margin-right:5px;}
.btnRight2 .text {font-size: 12px;font-weight: bolder;line-height: 30px}

.btnRight3 {text-align: right; }
.btnRight3 a {margin-left:7px; }
.btnRight3 a:first-child { margin-left:0; }

.btnRight3 .button {padding:0px 14px; margin-left: 7px;}
.btnRight3 .saveIcon {font-size: 20px; margin-right:5px;}
.btnRight3 .text {font-size: 12px;font-weight: bolder;line-height: 30px}

.wrap {overflow-x:hidden;}
body {height: 100%; background:#e9e9e9; }
.inner {width: 1243px; margin:0 auto; position: relative;}
.inner:after {content:''; display:block; clear:both;}

.headline {font-family: 'Noto Sans KR', sans-serif; font-size:15px;color:#d41826; font-weight: bold;}
.head {position: relative; background:#fff; width: 100%;}

.head:after {content:'';  display: block; width: 100%; height: 14px; bottom:-14px; background:url('../images/bgHead.png') repeat left top;}
.head .inner {height: 100px;}
.head .h1Logo {float:left; width: 91px; text-align: center; height:100px; line-height: 100px; text-align: center; overflow:hidden;}
.head .h1Logo img {max-width:91px; }
.head .h1Logo1 {float:left; width: 91px; text-align: center; height:100px; line-height: 100px; overflow:hidden;}
.head .h1Logo1 img {max-width:91px; vertical-align: middle;}
.head .gnbList {float:left; padding-left: 20px;}
.head .gnbList ul:after {content:''; display:block; clear:both;}
.head .gnbList > ul > li {float:left;  height: 114px; position: relative; text-align: center; }
.head .gnbList > ul > li:first-child a {border-left: 1px solid #e9e9e9;}
.head .gnbList > ul > li > a {display: block; height: 100px; padding-top: 20px; width: 100px; border-right: 1px solid #e9e9e9;}
.head .gnbList > ul > li > a span { display: block; color:#444444; font-size: 16px; font-weight: 500; line-height: 16px;}
.head .gnbList > ul > li > a span:before {content:''; height: 35px; display: block; width: 100%;}
.head .gnbList > ul > li.home > a span:before {background: url('../images/gnb01.png') center top no-repeat;}
.head .gnbList > ul > li.no1 > a span:before {background: url('../images/gnb02.png') center 4px no-repeat;}
.head .gnbList > ul > li.no2 > a span:before {background: url('../images/gnb07.png') center 4px no-repeat;}
.head .gnbList > ul > li.no3 > a span:before {background: url('../images/gnb03.png') center top no-repeat;}
.head .gnbList > ul > li.no4 > a span:before {background: url('../images/gnb06.png') center 2px no-repeat;}
.head .gnbList > ul > li.no5 > a span:before {background: url('../images/gnb04.png') center top no-repeat;}
.head .gnbList > ul > li.no6 > a span:before {background: url('../images/gnb08.png') center top no-repeat;}

.head .gnbList > ul > li.active.home > a span:before {background: url('../images/gnb01_on.png') center top no-repeat;}
.head .gnbList > ul > li.active.no1 > a span:before {background: url('../images/gnb02_on.png') center 4px no-repeat;}
.head .gnbList > ul > li.active.no2 > a span:before {background: url('../images/gnb07_on.png') center 4px no-repeat;}
.head .gnbList > ul > li.active.no3 > a span:before {background: url('../images/gnb03_on.png') center top no-repeat;}
.head .gnbList > ul > li.active.no4 > a span:before {background: url('../images/gnb06_on.png') center 2px no-repeat;}
.head .gnbList > ul > li.active.no5 > a span:before {background: url('../images/gnb04_on.png') center top no-repeat;}
.head .gnbList > ul > li.active.no6 > a span:before {background: url('../images/gnb08_on.png') center top no-repeat;}

.head .gnbList > ul > li.home:hover > a span:before {background: url('../images/gnb01_on.png') center top no-repeat;}
.head .gnbList > ul > li.no1:hover > a span:before {background: url('../images/gnb02_on.png') center 4px no-repeat;}
.head .gnbList > ul > li.no2:hover > a span:before {background: url('../images/gnb07_on.png') center 4px no-repeat;}
.head .gnbList > ul > li.no3:hover > a span:before {background: url('../images/gnb03_on.png') center top no-repeat;}
.head .gnbList > ul > li.no4:hover > a span:before {background: url('../images/gnb06_on.png') center 2px no-repeat;}
.head .gnbList > ul > li.no5:hover > a span:before {background: url('../images/gnb04_on.png') center top no-repeat;}
.head .gnbList > ul > li.no6:hover > a span:before {background: url('../images/gnb08_on.png') center top no-repeat;}

.head .gnbList > ul > li.active:after {content:''; position: absolute; left:0; bottom:0; height: 14px; width: 100%; background:#273751; z-index: 10;}
.head .gnbList > ul > li.active {background:#d41826; box-shadow:0 0 15px rgba(0,0,0,.3); }
.head .gnbList > ul > li.active > a {border:none;}
.head .gnbList > ul > li.active > a span {color:#fff;}

.head .gnbList > ul > li:hover:after {content:''; position: absolute; left:0; bottom:0; height: 14px; width: 100%; background:#2a3545; z-index: 10;}
.head .gnbList > ul > li:hover {background:#d41826; box-shadow:0 0 15px rgba(0,0,0,.3); }
.head .gnbList > ul > li:hover a {border:none;}
.head .gnbList > ul > li:hover a span {color:#fff;}
.head .gnbList .subGnb {position: absolute; left:0; top:100px; z-index: 30;  padding:40px 0 26px 0; width: 1000px; text-align: left; white-space:nowrap; display:none;}
.head .gnbList .subGnb > ul {display: inline-block;margin-right: 50px; vertical-align: top;}
.head .gnbList .subGnb > ul:first-child {margin-left: 20px;}
.head .gnbList .subGnb > ul li {margin-bottom: 18px; text-align: left;}
.head .gnbList .subGnb > ul li:last-child {margin-bottom: 0;}
.head .gnbList .subGnb > ul li a {font-size: 14px;line-height: 14px; color:#fff; font-weight: 500; position: relative; padding-left: 8px; display: block;}
.head .gnbList .subGnb > ul li a:hover {color:#fbffd7;}
.head .gnbList .subGnb > ul li a:after {content:''; position: absolute; left:0; top:50%; margin-top:-2px; width: 3px; height: 3px; background:#fff;}
.bgSub {position: absolute; top:114px; width: 100%; background:rgba(21,32,48,.9); min-height:230px;; z-index: 10; display: none;}


.head .rightInfo {float:right;}
.head .rightInfo:after {content:''; display:block; clear:both;}
.head .rightInfo .nameWrap {float:left; font-size: 15px; color:#666666; line-height: 15px; font-weight: 300; margin-top: 36px;}
.head .rightInfo .nameWrap span {font-size: 15px; color:#333333; font-weight: 500;}
.head .rightInfo .nameWrap em {color:#383838; font-weight: 700; display: inline-block; background: #cecece; padding:2px 3px; border:1px solid #404040; border-radius:3px; font-size:12px; line-height: 12px;}
.head .rightInfo .btnLogout {float:left; margin:27px 0 0 17px;}



.btn01 {display: inline-block; width:80px; line-height:30px; height: 30px; border-radius:3px; text-align: center; font-size: 12px;   font-weight: 500; color:#fff; background:#254c6e;}
.btn01.logout span{display: inline-block;  line-height: 13px; padding:2px 0 2px 16px; background:url('../images/ico_logout.png') left center no-repeat;}
.btn01.listUp span{display: inline-block;  line-height: 13px; padding:1px 0 3px 20px; background:url('../images/ico_listUp.png') left center no-repeat;}
.btn01.qna span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_qna.png') left center no-repeat;}
.btn01.search span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_search.png') left center no-repeat;}
.btn01.add span{display: inline-block;  line-height: 13px; padding:2px 0 2px 20px; background:url('../images/ico_plus.png') left center no-repeat;}
.btn01.save span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_save.png') left center no-repeat;}
.btn01.plus span{display: inline-block;  line-height: 13px; padding:0 0 0 21px; background:url('../images/ico_plus.png') left center no-repeat;}
.btn01.min span{display: inline-block;  line-height: 13px; padding:0 0 0 21px; background:url('../images/ico_min.png') left center no-repeat;}
.btn01.black {background:#3f3f3f; }

.btn02 {display: inline-block; width:150px; line-height:50px; height:50px; border-radius:3px; text-align: center; font-size: 16px;   font-weight: 500; color:#fff; background:#204a8b;}
.btn02.white {background:#fff; border:1px solid #d1d1d1; color:#333333;}
.btn02.white2 {background:#fff; border:1px solid #d1d1d1; color:#333333;}
.btn02.white:hover {background-color: rgb(219, 219, 219);}

.btn03 {display: inline-block; width:80px; line-height:30px; height: 30px; border-radius:3px; text-align: center; font-size: 12px;   font-weight: 500; color:#fff; background:#1caa7f;}
.btn03.logout span{display: inline-block;  line-height: 13px; padding:2px 0 2px 16px; background:url('../images/ico_logout.png') left center no-repeat;}
.btn03.listUp span{display: inline-block;  line-height: 13px; padding:1px 0 3px 20px; background:url('../images/ico_listUp.png') left center no-repeat;}
.btn03.qna span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_qna.png') left center no-repeat;}
.btn03.search span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_search.png') left center no-repeat;}
.btn03.add span{display: inline-block;  line-height: 13px; padding:2px 0 2px 20px; background:url('../images/ico_plus.png') left center no-repeat;}
.btn03.save span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_save.png') left center no-repeat;}
.btn03.plus span{display: inline-block;  line-height: 13px; padding:0 0 0 21px; background:url('../images/ico_plus.png') left center no-repeat;}
.btn03.min span{display: inline-block;  line-height: 13px; padding:0 0 0 21px; background:url('../images/ico_min.png') left center no-repeat;}
.btn03.black {background:#3f3f3f; }

.btn04 {display: inline-block; width:80px; line-height:20px; height: 20px; border-radius:3px; text-align: center; font-size: 12px;   font-weight: 500; color:#fff; background:#1caa7f;}
.btn04.logout span{display: inline-block;  line-height: 13px; padding:2px 0 2px 16px; background:url('../images/ico_logout.png') left center no-repeat;}
.btn04.listUp span{display: inline-block;  line-height: 13px; padding:1px 0 3px 20px; background:url('../images/ico_listUp.png') left center no-repeat;}
.btn04.qna span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_qna.png') left center no-repeat;}
.btn04.search span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_search.png') left center no-repeat;}
.btn04.add span{display: inline-block;  line-height: 13px; padding:2px 0 2px 20px; background:url('../images/ico_plus.png') left center no-repeat;}
.btn04.save span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_save.png') left center no-repeat;}
.btn04.plus span{display: inline-block;  line-height: 13px; padding:0 0 0 21px; background:url('../images/ico_plus.png') left center no-repeat;}
.btn04.min span{display: inline-block;  line-height: 13px; padding:0 0 0 21px; background:url('../images/ico_min.png') left center no-repeat;}
.btn04.black {background:#3f3f3f; }

.btn05 {display: inline-block; width:80px; line-height:20px; height: 20px; border-radius:3px; text-align: center; font-size: 12px;   font-weight: 500; color:#fff; background:#254c6e;}
.btn05.logout span{display: inline-block;  line-height: 13px; padding:2px 0 2px 16px; background:url('../images/ico_logout.png') left center no-repeat;}
.btn05.listUp span{display: inline-block;  line-height: 13px; padding:1px 0 3px 20px; background:url('../images/ico_listUp.png') left center no-repeat;}
.btn05.qna span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_qna.png') left center no-repeat;}
.btn05.search span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_search.png') left center no-repeat;}
.btn05.add span{display: inline-block;  line-height: 13px; padding:2px 0 2px 20px; background:url('../images/ico_plus.png') left center no-repeat;}
.btn05.save span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_save.png') left center no-repeat;}
.btn05.plus span{display: inline-block;  line-height: 13px; padding:0 0 0 21px; background:url('../images/ico_plus.png') left center no-repeat;}
.btn05.min span{display: inline-block;  line-height: 13px; padding:0 0 0 21px; background:url('../images/ico_min.png') left center no-repeat;}
.btn05.black {background:#3f3f3f; }

.btn06 {display: inline-block; width:80px; line-height:20px; height: 20px; border-radius:3px; text-align: center; font-size: 12px;   font-weight: 500; color:#fff; background:#f50057;}
.btn06.logout span{display: inline-block;  line-height: 13px; padding:2px 0 2px 16px; background:url('../images/ico_logout.png') left center no-repeat;}
.btn06.listUp span{display: inline-block;  line-height: 13px; padding:1px 0 3px 20px; background:url('../images/ico_listUp.png') left center no-repeat;}
.btn06.qna span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_qna.png') left center no-repeat;}
.btn06.search span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_search.png') left center no-repeat;}
.btn06.add span{display: inline-block;  line-height: 13px; padding:2px 0 2px 20px; background:url('../images/ico_plus.png') left center no-repeat;}
.btn06.save span{display: inline-block;  line-height: 13px; padding:2px 0 2px 22px; background:url('../images/ico_save.png') left center no-repeat;}
.btn06.plus span{display: inline-block;  line-height: 13px; padding:0 0 0 21px; background:url('../images/ico_plus.png') left center no-repeat;}
.btn06.min span{display: inline-block;  line-height: 13px; padding:0 0 0 21px; background:url('../images/ico_min.png') left center no-repeat;}
.btn06.black {background:#3f3f3f; }



.whiteWrap {margin-left:-20px; padding-top: 26px;}
.whiteWrap:after {content:''; display:block; clear:both;}
.whiteWrap .box {float:left; padding:20px 30px; margin-bottom: 32px; margin-left: 20px; height: 325px; background:#fff; border-radius:5px; width: 610px; box-shadow:0 0 10px rgba(0,0,0,.2);}
.whiteWrap .graph {border-top: 1px solid #12304b; padding-top: 15px; text-align: center; height:238px; }
.whiteWrap .graph:before {}
.whiteWrap .graph img {max-width:550px;}
.h2Wrap {position: relative; margin-bottom: 23px;}
.h2Wrap.mb0 {margin-bottom: 0;}
.h2Wrap.border {margin-bottom: 0; padding-bottom: 19px; border-bottom: 1px solid #cfcfcf;}
.h2Wrap h2 {font-size: 20px; color:#333333; font-weight:500; line-height: 20px; position: relative; padding-left: 14px;}
.h2Wrap h2:after {content:''; position: absolute; left:0; top:0; width: 5px; height: 20px; background: url('../images/h2_bull.png') no-repeat; }
.h2Wrap.blue h2:after {background: url('../images/h2_bull2.png') no-repeat; }
.h2Wrap .btn {position: absolute; right:0; top:-8px;}

.writeType01 {}
.writeType01 .fakeThead {display:table; border:1px solid #c8e1ff; border-radius:3px; text-align: center; width: 100%; background:#f1f8ff; height: 40px; padding:13px 0; margin-bottom: 10px;}
.writeType01 .fakeThead .tit {position: relative; display:table-cell; font-weight: 500; font-size: 15px; color:#373737; line-height: 15px; vertical-align: middle;}
.writeType01 .fakeThead .tit:after {content:''; position: absolute; left:0; top:0; width: 1px; height:100%; background:#71bddd;}
.writeType01 .fakeThead .tit:first-child:after {display: none;}
.writeType01 .fakeThead .tit.size01 {width: 112px;}
.writeType01 .fakeThead .tit.size02 {width: 100px;}
.writeType01 table {width: 100%;}
.writeType01 table tr:hover {background:#eeeeee;}
.writeType01 table td {font-size: 14px; line-height: 14px; color:#777777; font-weight: 400; text-align: center;height: 35px;}
.writeType01 table td a {font-size: 14px; line-height: 14px; color:#777777;}
.writeType01 table td.left {padding-left: 17px; text-align: left;}
.writeType01 table td span {color:#000000;}
.writeType01 table td span.re {background: #204a8b; display: inline-block; color:#fff;  font-size: 12px; width: 55px; height: 22px; text-align: center; line-height: 22px; border-radius:5px; vertical-align: middle;}

.bannerList {padding-bottom: 30px; display: flex; justify-content: center;}
.bannerList ul:after {content:''; display:block; clear:both;}
.bannerList ul li{float:left; background:#fff; margin-left:48px; border:1px solid #d6d5d5; width: 210px; height: 58px; line-height: 58px; overflow:hidden; text-align: center;}
.bannerList ul li:first-child {margin-left: 0;}
.bannerList ul li img {max-width:208px; vertical-align: middle; max-height:58px;}
.bannerList.four {width: 985px;}

.popArea {position: fixed; left:0; top:0; bottom:0; width: 100%; height: 100%; z-index: 100;}
.popArea .bg{position: fixed; left:0; top:0;  width: 100%; height: 100%; z-index: 1; background:rgba(0,0,0,.5);}
.popArea .popCont{position: absolute; left:50%; top:50%; z-index:10; width:1000px; height:815px; margin-top:-400px; margin-left:-500px; padding:40px 30px; background:#fff; border-radius:5px; box-shadow:0 0 15px rgba(0,0,0,.3); overflow: auto; }
.popArea .btnClose {position: absolute; right:30px; top:20px; cursor:pointer;}
.popArea .btnClose a {display: block; text-indent:-999em; width: 25px; height: 25px; background: url('../images/btnClose.png') no-repeat;}
.popArea .popCont .desc {font-size: 14px; color:#d41826; margin-top: -20px; padding-bottom: 15px;}

.popSmArea {position: fixed; left:0; top:0; bottom:0; width: 100%; height: 100%; z-index: 100;}
.popSmArea .bg{position: fixed; left:0; top:0;  width: 100%; height: 100%; z-index: 1; background:rgba(0,0,0,.5);}
.popSmArea .popCont{position: absolute; left:50%; top:50%; z-index:10; width:600px; height:400px; margin-top:-300px; margin-left:-300px; padding:40px 30px; background:#fff; border-radius:5px; box-shadow:0 0 15px rgba(0,0,0,.3); overflow: auto; }
.popSmArea .btnClose {position: absolute; right:30px; top:20px; cursor:pointer;}
.popSmArea .btnClose a {display: block; text-indent:-999em; width: 25px; height: 25px; background: url('../images/btnClose.png') no-repeat;}

.popSmArea {position: fixed; left:0; top:0; bottom:0; width: 100%; height: 100%; z-index: 100;}
.popSmArea .bg{position: fixed; left:0; top:0;  width: 100%; height: 100%; z-index: 1; background:rgba(0,0,0,.5);}
.popSmArea .popCont{position: absolute; left:50%; top:50%; z-index:10; width:600px; height:400px; margin-top:-300px; margin-left:-300px; padding:40px 30px; background:#fff; border-radius:5px; box-shadow:0 0 15px rgba(0,0,0,.3); overflow: auto; }
.popSmArea .btnClose {position: absolute; right:30px; top:20px; cursor:pointer;}
.popSmArea .btnClose a {display: block; text-indent:-999em; width: 25px; height: 25px; background: url('../images/btnClose.png') no-repeat;}


.h2Wrap h2 span {font-size: 14px; color:#d41826; padding-left: 20px;}

.writeType02 {margin-bottom: 25px;}
.writeType02.mb0 {margin-bottom: 0;}
.writeType02 table {width: 100%; border-top: 2px solid #4d6ea1;}
.writeType02 table th {background: #f8f8f8; text-align: center; font-size: 14px; color:#333333; font-weight: 500; border-bottom: 1px solid #d1d1d1;;}
.writeType02 table td {padding:9px 12px 9px 12px; border-bottom: 1px solid #d1d1d1;}
.writeType02 table td input[type=text] {border:1px solid #d1d1d1; height: 32px; padding:0 0 0 10px; width: 100%;}
.writeType02 table td input[type=password] {border:1px solid #d1d1d1; height: 32px; padding:0 0 0 10px; width: 100%;}
.writeType02 table td input[type=file] {}
.writeType02 table td textarea {border:1px solid #d1d1d1; height: 313px; padding:10px; width: 100%; resize: none;}
.writeType02 table td span.desc {font-size: 14px; color:#d41826; margin-top: -20px; padding-bottom: 15px;}
.writeType02 table .star {font-size: 16px; color:#d41826;}
.writeType02 table .fileWrap {margin-bottom: 10px;}
.writeType02 table .fileWrap:after {content:''; display:block; clear:both;}
.writeType02 table .fileWrap:last-child {margin-bottom: 0;}

.fileSearch {position: relative;  margin-right:5px; display: inline-block;  width: 665px; padding-top: 1px;}
.fileSearch .file_input_textbox {border:1px solid #d1d1d1;height: 32px;  width:572px !important; margin-left: 91px;}
.fileSearch .file_input_div{position: absolute; left:0; top:0; overflow: hidden; width:100%; height: 35px; }
.fileSearch .file_input_button{width:100%; height: 35px; position: absolute; left:0; top: 0;background: url('../images/btnFile.png') no-repeat; cursor:pointer;  border:none; }
.fileSearch .file_input_hidden{font-size: 45px;position: absolute;right: 0px;top: 0px;opacity: 0; filter: alpha(opacity=0);-ms-filter: "alpha(opacity=0)";-khtml-opacity: 0;-moz-opacity: 0; cursor:pointer;}

.scrollWrap * {opacity:1 !important}


.qnaList .scrollWrap {height: 670px;}
.qnaList .writeType02 {border:1px solid #d1d1d1;}
.qnaList .writeType02 td { height:50px; font-size: 14px; color:#333333; font-weight: 400;}
.qnaList .writeType02 td .file{ color:#a1a1a1; display: inline-block; vertical-align: middle; padding-right:20px; margin-right: 10px; background: url('../images/ico_down.png') right center no-repeat;}
.qnaList .writeType02 td.contTd {padding-left:20px; padding-right:20px; border-bottom:none; }
.qnaList .writeType02 td.contTd .content {height:310px;}

.qnaList .scrollWrap .mCSB_inside > .mCSB_container {margin-right:25px !important;}
.qnaList .scrollWrap .mCSB_scrollTools .mCSB_draggerRail {width: 15px !important; background:#dcdcdc !important; border-radius:5px;}
.qnaList .scrollWrap .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {width: 15px !important; background:#4d6ea1 !important; border-radius:5px;}


.repleWrap {background: #f8f8f8; border:1px solid #d1d1d1; border-top:none; padding:30px; margin-bottom:30px;}
.repleWrap .white:after {content:''; display:block; clear:both;}
.repleWrap .white .tit {float:left; font-size: 14px; color:#4a4a4a; font-weight: 500; line-height: 14px; padding-top: 10px; width:69px;}
.repleWrap .white .text {float:left;}
.repleWrap .white .text textarea {background: #fff; border:1px solid #d1d1d1; resize:none; height: 60px; padding:10px; width: 695px;}
.repleWrap .white .btnUp {float:right; width: 70px;}
.repleWrap .repleList {}
.repleWrap .repleList ul li {padding-top: 24px; margin-top: 24px; border-top: 1px solid #d1d1d1;}
.repleWrap .repleList ul li .name {font-size: 15px; color:#254c6e; font-weight: 500; margin-bottom:7px; line-height: 15px;}
.repleWrap .repleList ul li .text {font-size: 14px; color:#4a4a4a; font-weight: 400; margin-bottom:20px; }
.repleWrap .repleList ul li .date {font-size: 12px; color:#4a4a4a; font-weight: 300;}



.leftMenu {position: absolute; left:0; top:0; width: 240px; background:#f8f8f8; padding:28px 19px 28px 40px;}
.leftMenu ul li { padding-bottom: 18px; margin-bottom: 14px; border-bottom: 1px solid #dfe2e4;}
.leftMenu ul li a {display: block; font-size: 15px; color:#333333; line-height: 15px; position: relative; }
.leftMenu ul li.active a { color:#d41826; }
.leftMenu ul li.active a:after {content:''; position: absolute; right:0; top:5px;  width: 12px; height: 2px; background:#d41826; }
.leftMenu ul li:hover a { color:#d41826; }
.leftMenu ul li:hover a:after {content:''; position: absolute; right:0; top:5px;  width: 12px; height: 2px; background:#d41826; }
.rightCont .btnRight {position: absolute; right:0; top:-8px; }
.rightCont .btnRight a {float:left; margin-left:7px;}
.rightCont {margin-left: 240px; padding:38px 32px 38px 32px; background:#fff; height:1300px;}
.rightCont2 .btnRight {position: absolute; right:0; top:-8px; }
.rightCont2 .btnRight a {float:left; margin-left:7px;}
.rightCont2 {margin-left: 240px; padding:38px 32px 38px 32px; background:#fff;}
.rightContLong .btnRight {position: absolute; right:0; top:-8px; }
.rightContLong .btnRight a {float:left; margin-left:7px;}
.rightContLong {margin-left: 240px; padding:38px 32px 38px 32px; background:#fff; min-height:100vh;height:1080px;}

.wideRightCont .btnRight {position: absolute; right:0; top:-8px; }
.wideRightCont .btnRight a {float:left; margin-left:7px;}
.wideRightCont {padding:38px 32px 38px 32px; background:#fff;  min-height:100vh;}

.h2Wrap.mb30 {margin-bottom: 30px;}

.writeType03 .cpPop {text-align: center;}
.writeType03 .cpPop th {background: #f5f5f5; text-align: center; padding:0 0; font-size: 13.5px; color:#333333; font-weight: 500; border-bottom: 1px solid #ebebeb; line-height: 15px;}

.writeType03 {margin-bottom: 15px;}
.writeType03.mb0 {margin-bottom: 0;}
.writeType03 table {width: 100%; border-top: 1px solid #ebebeb;}
.writeType03 table th {background: #f5f5f5; text-align: left; font-size: 13.5px; color:#333333; font-weight: 500; border-bottom: 1px solid #ebebeb;; padding:15px 0 15px 15px; line-height: 15px;}
.writeType03 table td {padding:9px 15px 9px 9px; border-bottom: 1px solid #ebebeb;}
.writeType03 table td select {border:1px solid #dddddd; padding-left: 10px; height: 25px; width: 100%;}
.writeType03 table td input[type=text] {border:1px solid #dddddd; height: 25px; padding:0 0 0 10px; width: 100%;}
.writeType03 table td input[type=text].size01 {border:1px solid #dddddd; height: 25px; padding:0 0 0 10px; margin-right: 10px; width: 60%;}
.writeType03 table td textarea {border:1px solid #dddddd; height: 420px; padding:10px; width: 100%; resize: none;}

.hori {overflow-x:auto; white-space:nowrap;}

.writeType04 {min-height: 600px; margin-top: 30px;}
.writeType04 table {width: 100%;}
.writeType04 table thead {border:1px solid #c8e1ff; border-radius:5px; text-align: center; width: 100%; background:#f1f8ff; height: 40px;}
.writeType04 table th {position: relative; font-weight: 500; font-size: 13px; color:#373737; line-height: 13px; vertical-align: middle; padding:10px 0;}
.writeType04 table th:after {content:''; position: absolute; left:0; top:12px; width: 1px; height:15px;  background:#71bddd;}
.writeType04 table th:first-child:after {display: none;}
.writeType04 table td {text-align: center; font-size: 13px; padding:5px;}

.writeType04 .scrollWrap .tableSc {min-height:300px;}
.writeType04 .scrollWrap .mCSB_scrollTools .mCSB_draggerRail {height: 15px !important; background:#dcdcdc !important; border-radius:5px;}
.writeType04 .scrollWrap .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {margin:0 !important; height: 15px !important; background:#4d6ea1 !important; border-radius:5px;}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {margin:0 !important}

.writeType05 {margin-bottom: 15px;}
.writeType05.short {min-height: 300px;}
.writeType05.long {min-height: 600px;}
.writeType05.long2 {min-height: 700px;}
.writeType05.long3 {min-height: 800px;}
.writeType05 div.left {float:left; width:30%;}
.writeType05 div.right {float:right; width:65%; height:600px;}
.writeType05 div.right1 {float:right; width:65%; height:250px; margin-bottom: 50px}
.writeType05 div.left2 {float:left; width:50%;}
.writeType05 div.right2 {float:right; width:49.5%; height:600px;}
.writeType05 div.left3 {float:left; width:68%;}
.writeType05 div.right3 {float:right; width:30%; height:300px;}
.writeType05.mb0 {margin-bottom: 0;}
.writeType05 table {width: 100%; border-top: 1px solid #ebebeb;}
.writeType05 table th {background: #f5f5f5; text-align: left; font-size: 13.5px; color:#333333; font-weight: 500; border-bottom: 1px solid #ebebeb;; padding:15px 0 15px 15px; line-height: 15px;}
.writeType05 table td {padding:9px 15px 9px 9px; border-bottom: 1px solid #ebebeb;}
.writeType05 table td select {border:1px solid #dddddd; padding-left: 10px; height: 25px; width: 100%;}
.writeType05 table td input[type=text] {border:1px solid #dddddd; height: 25px; padding:0 0 0 10px; width: 100%;}
.writeType05 table td input[type=text].size00 {border:1px solid #dddddd; height: 25px; padding:0 0 0 10px; margin-right: 10px; width: 80%;}
.writeType05 table td input[type=text].size01 {border:1px solid #dddddd; height: 25px; padding:0 0 0 10px; margin-right: 10px; width: 60%;}
.writeType05 table td input[type=text].size02 {border:1px solid #dddddd; height: 25px; padding:0 0 0 10px; margin-right: 10px; width: 34.3%;}
.writeType05 table td input[type=text].size03 {border:1px solid #dddddd; height: 25px; padding:0 0 0 10px; margin-right: 5px; width: 40%;}
.writeType05 table td input[type=text].size04 {border:1px solid #dddddd; height: 25px; padding:0 0 0 10px; margin-right: 5px; width: 15%;}
.writeType05 table td input[type=text].size05 {border:1px solid #dddddd; height: 25px; padding:0 0 0 10px; width: 25%;}
.writeType05 table td input[type=text].size06 {border:1px solid #dddddd; height: 25px; padding:0 0 0 10px; margin-left: 5px; width: 40%;}
.writeType05 table td .dash {text-align: center; width:25px; vertical-align: middle; display: inline-block;}
.writeType05 table td input[type=password] {border:1px solid #d1d1d1; height: 25px; padding:0 0 0 10px; width: 100%;}
.writeType05 table .star {font-size: 16px; color:#d41826;}
.writeType05 table td textarea {border:1px solid #dddddd; height: 420px; padding:10px; width: 100%; resize: none;}
.writeType05 table td textarea.size01 {border:1px solid #dddddd; height: 70px; padding:0 0 0 10px; width: 100%; resize: none; margin-bottom: -5px;}

.count {font-size: 13px; color:#333333; font-weight: 300; padding-top: 10px; margin-top: 6px; border-top: 1px solid #e0e0e0;}

.writeType03_acc {margin-bottom: 15px;}
.writeType03_acc.mb0 {margin-bottom: 0;}
.writeType03_acc table {width: 100%; border-top: 1px solid #ebebeb;}
.writeType03_acc table th {background: #f5f5f5; text-align: center; font-size: 13.5px; font-weight: 500; color:#333333; border-bottom: 1px solid #ebebeb; line-height: 15px;}
.writeType03_acc table td {padding:9px 15px 9px 9px; border-bottom: 1px solid #ebebeb;}
.writeType03_acc table td select {border:1px solid #dddddd; padding-left: 10px; height: 25px; width: 100%;}
.writeType03_acc table td input[type=text] {border:1px solid #dddddd; height: 25px; padding:0 0 0 3px; width: 100%; font-size: 14px; font-weight: 400}
.writeType03_acc table td input[type=text].size01 {border:1px solid #dddddd; height: 25px; padding:0 0 0 10px; margin-right: 10px; width: 60%;}
.writeType03_acc table td textarea {border:1px solid #dddddd; height: 420px; padding:10px; width: 100%; resize: none;}

.writeType03_noborder {margin-bottom: 15px;}
.writeType03_noborder.mb0 {margin-bottom: 0;}
.writeType03_noborder table {width: 100%; border-top: 1px solid #ebebeb;}
.writeType03_noborder table th {background: #f5f5f5; text-align: center; font-size: 13.5px; font-weight: 500; color:#333333; border-bottom: 1px solid #ebebeb; line-height: 15px;}
.writeType03_noborder table td {padding:9px 15px 9px 9px; border-bottom: 1px solid #ebebeb;}
.writeType03_noborder table td select {border:0; padding-left: 10px; height: 25px; width: 100%;}
.writeType03_noborder table td input[type=text] {border:0; height: 25px; padding:0 0 0 10px; width: 100%; font-size: 14px; font-weight: 400}
.writeType03_noborder table td input[type=text].size01 {border:0; height: 25px; padding:0 0 0 10px; margin-right: 10px; width: 60%;}
.writeType03_noborder table td textarea {border:0; height: 420px; padding:10px; width: 100%; resize: none;}

.loginWrap {padding-top: 125px; width:1000px; margin:0 auto;}
.loginWrap .h1Logoin {text-align: center; margin-bottom:35px;}
.loginWrap .member {width:715px; background:#fff url('../images/bgLogin.png') 42px 112px no-repeat; border-radius:5px;  box-shadow:0 0 10px rgba(0,0,0,.1); margin:0 auto 25px auto; padding:40px 0;}
.loginWrap .member .tit {text-align: center; font-size: 30px; color:#6e6e6e; font-weight: 500; line-height: 30px; margin-bottom:45px;}
.loginWrap .member .inputWrap {margin-left: 358px; border-left: 1px solid #ccd1d8;  padding-left: 57px; padding-top: 20px;}
.loginWrap .member .inputWrap .box {margin-bottom: 10px; width: 240px;}
.loginWrap .member .inputWrap .box input {border:1px solid #d1d1d1; height: 50px; padding:0 0 0 18px; background:#fafafa; width: 100%;}
.loginWrap .member .btnLogin {width: 240px; margin-bottom:20px;}
.loginWrap .member .btnLogin a {display: block; text-align: center; color:#fff; border-radius:3px; height: 60px; line-height: 60px; font-size: 17px; font-weight:500;  background:#d41826; }
.loginWrap .member .link a{font-size: 15px; color:#333333; line-height: 15px; display: inline-block; vertical-align: middle;}
.loginWrap .member .link a:last-child {position: relative; padding-left: 10px; margin-left: 7px;}
.loginWrap .member .link a:last-child:after {content:''; position: absolute; left:0; top:0;  height: 12px; width: 1px; background:#b9b9b9;}
.loginWrap .info {text-align: center; margin-bottom: 50px;}
.loginWrap .info a {display: inline-block; position: relative; font-weight:700; color:#6e6e6e; font-size: 18px; line-height: 18px;}
/*.loginWrap .info a:last-child {color:#d41826}*/
.loginWrap .info a:first-child {padding-right: 46px; margin-right: 43px;}
/*.loginWrap .info a:first-child:after {content:''; position: absolute; right:0; top:0; width: 1px; height:17px; background:#b9b9b9;}*/


.baseCehck {margin-bottom: 15px;}
.baseCehck input {position: absolute; visibility:hidden;}
.baseCehck label {position: relative; padding-left:30px; font-size: 15px; line-height: 15px; color:#444444; letter-spacing:-1px;}
.baseCehck label:after {content:''; position: absolute; left:0; top:1px; width:20px; height: 20px; background: url('../images/checkOff.png') no-repeat;}
.baseCehck input:checked +label:after { background: url('../images/checkOn.png') no-repeat;}



.idpwSearch .popCont {width: 710px; margin-left:-355px; margin-top:-195px; height:390px;}
.idpwSearch .popCont .tab {text-align: center; margin-bottom: 40px; padding-top: 20px;}
.idpwSearch .popCont .tab a{font-size: 20px; color:#6e6e6e; line-height: 20px; display: inline-block;}
.idpwSearch .popCont .tab a:first-child {margin-right: 30px;}
.idpwSearch .popCont .tab a.active {color:#d41826; font-weight: 700; padding-bottom:5px; border-bottom: 1px solid #d41826;;}
.idpwSearch .tabBox {width: 436px; margin:0 auto;}
.idpwSearch .tabBox > div:first-child {display: block;}
.idpwSearch .tabBox > div {display: none;}
.idpwSearch .tabBox .inputLine  {margin-bottom: 10px; }
.idpwSearch .tabBox .inputLine .tit {float:left; font-size: 16px; font-weight: 500; color:#000000; position: relative; width: 75px; padding-left: 14px;margin-top: 15px;}
.idpwSearch .tabBox .inputLine .tit:after {content:''; position: absolute; left:0; top:50%; width: 3px; height: 3px; margin-top:-2px; background:#d41826;}
.idpwSearch .tabBox .inputLine .input {float:left;}
.idpwSearch .tabBox .inputLine .input input {background:#fafafa; border:1px solid #d1d1d1; width: 360px; height: 50px; padding-left: 10px;}
.idpwSearch .tabBox .inputLine:after {content:''; display:block; clear:both;}
.idpwSearch .tabBox .btn {margin-left: 75px; text-align: center; margin-top: 20px;}
.idpwSearch .tabBox .btn a {display: block; background:#363636; height: 60px; line-height: 60px; font-size: 17px; color:#fff; border-radius:3px; }
.idpwSearch .tabBox .btn a span {display: inline-block; padding-right:36px; background:url('../images/ico_logSearch.png') right center no-repeat;}

.popArea.join .popCont {padding-top: 30px;}
.agreeBox {margin-bottom:10px; border:1px solid #cccccc; border-radius:3px; }
.agreeBox .scrollWrap {height: 200px; overflow:auto}
.agreeBox .in {padding:20px; font-size: 13px; color:#888888; line-height: 26px;}
.agreeBox .in .bold {color:#000000; font-weight: 700;}
.agreeBox .tit{font-size: 21px; line-height: 21px; color:#333333; font-weight: 700; padding-bottom: 28px; border-bottom: 2px solid #006bb7; margin-bottom: 5px;}
.agreeBox .agreeCont{font-size:15px; line-height: 23px; color:#666666; margin-bottom: 28px; border:1px solid #e2e2e2; margin:0 1px 17px 1px; padding:30px;}
.agreeBox .agreeCheck input {margin-right: 5px; margin-top: 0; vertical-align: middle;}
.agreeBox .agreeCheck label {font-size: 15px; font-weight: 700; color:#222222; margin-right: 29px; vertical-align: middle;}
.agreeBox .scrollWrap .mCSB_inside > .mCSB_container {margin-right:25px !important;}
.agreeBox .scrollWrap .mCSB_scrollTools .mCSB_draggerRail {width: 15px !important; background:#dcdcdc !important; border-radius:5px;}
.agreeBox .scrollWrap .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {width: 15px !important; background:#4d6ea1 !important; border-radius:5px;}
.agreeBox .scrollWrap .mCSB_scrollTools {right:6px; top:5px; bottom:5px;}

.agreeBox table td {
	text-align: center;
	font-size: 0.93rem;
	font-weight: 400;
	letter-spacing: -0.3px;
	line-height: 22px;
	color: #888;
	padding: 20px;
	border-right: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
}

.agreeBox table th {
	background: #e9e9e9;
	font-size: 1.06rem;
	font-weight: 700;
	letter-spacing: -0.3px;
	line-height: 22px;
	color: #313131;
	padding: 20px 10px;
	text-align: center;
	border-right: 1px solid #fff;
}

.fms {margin-bottom:10px; border:1px solid #cccccc; border-radius:3px; padding: '20px 20px 40px 20px';background: #fff ;text-align: center;}
.fms .scrollWrap {height: 200px; overflow:auto}
.fms .in {padding:20px; font-size: 13px; color:#888888; line-height: 26px;}
.fms .in .bold {color:#000000; font-weight: 700;}
.fms a.button{height: 70px;font-size: 1.2em;color:#222222;background:#fff !important;border:1px solid #4d6ea1;border-radius:5px;padding: 5px;}
.fms .img {max-width: 100%;}

.fms table td {
	text-align: center;
	font-size: 0.93rem;
	font-weight: 400;
	letter-spacing: -0.3px;
	line-height: 22px;
	color: #888;
	padding: 20px;
	border-right: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
}

.fms table th {
	background: #e9e9e9;
	font-size: 1.06rem;
	font-weight: 700;
	letter-spacing: -0.3px;
	line-height: 22px;
	color: #313131;
	padding: 20px 10px;
	text-align: center;
	border-right: 1px solid #fff;
}


.writeType02.type2 th {text-align: left; padding-left: 20px;}
.writeType02.type2 table td {padding:7px 12px 7px 12px;}
.writeType02.type2 table td input[type=text] {height: 35px; width:270px;}
.writeType02.type2 table td input[type=password] {height: 35px; width:270px;}
.writeType02.type2 table td input[type=text].size01 {width:132px;}
.writeType02.type2 table td input[type=text].size02 {width:500px;}
.writeType02.type2 table td input[type=text].size03 {width:130px;}
.writeType02.type2 table td input[type=text].size04 {width:275px;}
.writeType02.type2 table td input[type=text].size05 {width:80px; margin-right: 9px;}
.writeType02.type2 table td .dash {text-align: center; width:25px; vertical-align: middle; display: inline-block;}
.writeType02.type2 table td a {margin-left: 6px;}
.writeType02.type2 table td .btnInTable {margin-left: 6px;}
.writeType02.type2 table td .iconButton {margin-left: 6px;}
.writeType02.type2 table td a.black {margin-right: 10px;}
.writeType02.type2 table td .btnInTable.mr {margin-right: 12px;}
.txtCenter {text-align: 'center'}

table tbody tr td .iconButton {padding: 0px}
table tbody tr td .btnInTable {padding:0px 14px;font-size: 12px;font-weight: bolder;line-height: 30px;margin: -6px 0px;}

/* paging */
.paging{width:100%;text-align:center;font-size:0;}
.paging a.button{display:inline-block;width:21px;height:21px;border:0;overflow:hidden;text-indent:-99999px;background:#fff;background-image:url('../images/icon.png');background-repeat:no-repeat;}
.paging a.button.prev{border-left:0;background-position:-55px 7px;}
.paging a.button.next{border-left:0;background-position:-83px 7px;}
.paging a{display:inline-block;width:30px;height:21px;line-height:21px;vertical-align:top;font-size:14px;color:#848484;text-align:center;font-weight:700;}
.paging a.on{width:30px;height:21px;line-height:21px;color:#c60010;}

/* sheet */
.scroll{ -ms-overflow-style: auto; }
.scroll::-webkit-scrollbar {display: block;}
.scroll::-webkit-scrollbar{width: 10px}
.scroll::-webkit-scrollbar-track{background-color: #ffffff;}
.scroll::-webkit-scrollbar-thumb{background-color: #f5f5f5; border-radius: 10px}
.scroll::-webkit-scrollbar-thumb:hover{background: #222222}
.scroll::-webkit-scrollbar-button:start:decrement,.scroll::-webkit-scrollbar-button:end:increment{width:6px; height:6px; background: #ffffff}

@media all and (max-height:550px) {
	.popArea  {position: absolute;}
	.popArea .popCont {top:10px; margin-top:0;}
	.popSmArea  {position: absolute;}
	.popSmArea .popCont {top:10px; margin-top:0;}
}